class MenuUnderline {
	constructor() {
		this.initMenus();
		this.isStickyHeaderActive = false;
	}

	initMenus() {
		// Regular header menu
		this.menu = document.querySelector('.site-header .main-menu__list');
		if (this.menu) {
			this.initUnderline();
		}

		// sticky header menus
		window.addEventListener('scroll', () => {
			const stickyHeader = document.querySelector('.sticky-header');
			const stickyHeaderVisible =
				stickyHeader && stickyHeader.offsetTop < window.scrollY;

			if (stickyHeaderVisible && !this.isStickyHeaderActive) {
				this.menu = document.querySelector('.sticky-header .main-menu__list');
				if (this.menu && !this.menu.querySelector('.main-menu__underline')) {
					this.initUnderline();
				}
				this.isStickyHeaderActive = true;
			}

			if (!stickyHeaderVisible && this.isStickyHeaderActive) {
				// Reset to the regular header when scrolling back up
				this.menu = document.querySelector('.site-header .main-menu__list');
				if (this.menu) {
					this.resetUnderline();
				}
				this.isStickyHeaderActive = false;
			}
		});
	}

	initUnderline() {
		this.menuItems = this.menu.querySelectorAll(
			'.main-menu__list-item:not(.main-menu__dropdown-item)' // Exclude dropdown items
		);
		this.underline = document.createElement('div');
		this.underline.classList.add('main-menu__underline');
		this.menu.appendChild(this.underline);
		this.addEventListeners();
		this.moveUnderlineToCurrent();
	}

	resetUnderline() {
		if (this.underline) {
			this.underline.remove();
		}
		this.initUnderline();
	}

	setUnderline(position, width) {
		this.underline.style.left = `${position}px`;
		this.underline.style.width = `${width}px`;
	}

	moveUnderlineToCurrent() {
		const currentItem = this.menu.querySelector(
			'.main-menu__list-item.current'
		);
		if (currentItem) {
			this.updateUnderlinePosition(currentItem);
		} else {
			// If no current item, shrink the underline to 0 width
			this.setUnderline(
				this.underline.offsetLeft + this.underline.offsetWidth / 2,
				0
			);
		}
	}

	updateUnderlinePosition(element) {
		const textWidth = element.querySelector('a')
			? element.querySelector('a').offsetWidth
			: element.clientWidth;
		const centerPosition =
			element.offsetLeft + element.offsetWidth / 2 - textWidth / 2;
		this.setUnderline(centerPosition, textWidth);
	}

	addEventListeners() {
		this.menuItems.forEach((item) => {
			item.addEventListener('mouseenter', () => {
				this.updateUnderlinePosition(item);
			});

			item.addEventListener('mouseleave', () => {
				this.moveUnderlineToCurrent();
			});

			item.addEventListener('click', () => {
				this.updateUnderlinePosition(item);
				this.manageCurrentItem(item);
			});
		});
	}

	manageCurrentItem(newCurrentItem) {
		this.menuItems.forEach((item) => {
			item.classList.remove('current');
		});
		newCurrentItem.classList.add('current');
	}
}

export default MenuUnderline;
