import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Header from './components/Header';
import ClientLogos from './components/ClientLogos';
import Testimonials from './components/Testimonials';
import ClientCandidateServices from './components/ClientCandidateServices';
import ZenonDifferenceCarousel from './components/ZenonDifferenceCarousel';
import ServicesWeOffer from './components/ServicesWeOffer';
import ProviderAccordion from './components/ProviderAccordion';
import JobApplication from './components/JobApplication';
import ApplicationForm from './components/ApplicationForm';
import SaveJob from './components/SaveJob';
import SubmitCv from './components/SubmitCv';
import MenuUnderline from './components/MenuUnderline';
import CustomUpload from './components/CustomUpload';
import FormHandler from './components/FormHandler';

for (const header of document.querySelectorAll('.site-header')) {
	new Header(header);
}

document.querySelectorAll('.pelling-client-logos').forEach((block) => {
	new ClientLogos(block);
});

document.querySelectorAll('.pelling-testimonials').forEach((block) => {
	new Testimonials(block);
});

document.querySelectorAll('.pelling-cc-services').forEach((block) => {
	new ClientCandidateServices(block);
});

document
	.querySelectorAll('.pelling-zenon-difference-carousel')
	.forEach((block) => {
		new ZenonDifferenceCarousel(block);
	});

document.querySelectorAll('.pelling-services-we-offer').forEach((block) => {
	new ServicesWeOffer(block);
});

document.querySelectorAll('.provider-accordion').forEach((accordion) => {
	new ProviderAccordion(accordion);
});

document.addEventListener('DOMContentLoaded', () => {
	new JobApplication();
	new ApplicationForm();
	new SaveJob();
	// new SubmitCv();
	new MenuUnderline();
	new CustomUpload();
	new FormHandler(
		'cvForm',
		'submitCvModal',
		'Your CV has been sent successfully!',
		'submit_cv'
	);
	new FormHandler(
		'registerForm',
		'registerJobSeekerModal',
		'You have successfully registered as a job seeker!',
		'register_job_seeker'
	);
	new FormHandler(
		'vacancyForm',
		'registerVacancyModal',
		'Your vacancy has been registered successfully!',
		'register_vacancy'
	);
});

//miscellaneous
document.addEventListener('DOMContentLoaded', () => {
	const facetwpTemplate = document.querySelector(
		'.facetwp-template[data-name="job_template"]'
	);
	if (facetwpTemplate) {
		facetwpTemplate.classList.add(
			'job-listings__cards',
			'row',
			'gy-4',
			'justify-content-center'
		);
	}
});

document.addEventListener('DOMContentLoaded', () => {
	const descriptionContainers = document.querySelectorAll(
		'.service-single__description'
	);

	descriptionContainers.forEach((container) => {
		const uls = container.querySelectorAll('ul');

		uls.forEach((ul) => {
			const listItems = ul.querySelectorAll('li');
			if (listItems.length > 8) {
				ul.classList.add('two-columns');
			}
		});
	});
});

document.addEventListener('facetwp-loaded', function () {
	const loadMoreButton = document.querySelector('.facetwp-load-more');
	if (loadMoreButton) {
		loadMoreButton.classList.add(
			'btn-pelling',
			'btn-pelling--z-blue',
			'outline',
			'd-block',
			'mx-auto'
		);
	}
});
