import GdprHandler from './GdprHandler';

class JobApplication {
	constructor() {
		this.applyNowStep2 = document.querySelectorAll(
			'.job-single__app-step--2 .job-single__button--apply'
		);

		this.step2 = document.querySelector('.job-single__app-step--2');
		this.step3 = document.querySelector('.job-single__app-step--3');
		this.step4 = document.querySelector('.job-single__app-step--4');

		this.cf7Form = document.querySelector('#applicationForm');

		this.steps = [this.step2, this.step3, this.step4];
		this.jobSlug = this.getJobSlugFromUrl();
		this.siteUrl = siteData.siteUrl;
		this.currentAppStep = 1;
		this.formId = 'applicationForm';

		if (this.step2 && this.step3 && this.step4) {
			this.init();
		}

		document.addEventListener(
			'wpcf7mailsent',
			this.handleFormSubmit.bind(this),
			false
		);
	}

	init() {
		this.steps.forEach((step) => (step.style.display = 'none'));
		this.showCurrentStep();
		this.setHiddenFieldValues();

		this.attachApplyButtonListeners();

		window.addEventListener('popstate', (event) => {
			const step = event.state ? event.state.step : 1;
			this.handleBackNavigation(step);
		});

		new GdprHandler(this.formId);
	}

	handleBackNavigation(step) {
		if (this.currentAppStep === 3) {
			this.showStep(this.currentAppStep, 1, false);
			this.resetCf7Form();
		} else if (this.currentAppStep === 2) {
			this.showStep(this.currentAppStep, 1, false);
		} else if (this.currentAppStep === 1) {
			window.location.href = '/candidates/';
		}
		this.currentAppStep = step;
	}

	attachApplyButtonListeners() {
		this.applyNowStep2.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				this.showStep(1, 2);
			});
		});
	}

	async fetchJobDetails() {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/job?slug=${this.jobSlug}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch job details');
		}
		const jobDataArray = await response.json();
		const jobData = jobDataArray[0];

		const consultantData = await this.fetchConsultantDetails(
			jobData.consultant[0]
		);

		return {
			jobData,
			consultantData,
		};
	}

	async fetchConsultantDetails(consultantId) {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/consultant/${consultantId}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch consultant details');
		}
		const consultantData = await response.json();
		return consultantData;
	}

	async setHiddenFieldValues() {
		try {
			const { jobData, consultantData } = await this.fetchJobDetails();

			const jobTitleField = document.getElementById('app-form-job-title');
			const consultantEmailField = document.getElementById('consultant-email');
			const jobRefNumberField = document.getElementById(
				'app-form-job-ref-number'
			);

			if (jobTitleField) {
				jobTitleField.value = jobData.acf.title;
			}

			if (consultantEmailField) {
				consultantEmailField.value = consultantData.acf.email;
			}

			if (jobRefNumberField) {
				jobRefNumberField.value = jobData.acf.job_reference_number;
			}
		} catch (error) {
			console.error('Error setting hidden field values:', error);
		}
	}

	showStep(currentStep, nextStep, pushState = true) {
		this.steps[currentStep - 1].style.display = 'none';
		this.steps[nextStep - 1].style.display = 'block';
		this.steps[nextStep - 1].scrollIntoView({
			behavior: 'smooth',
		});
		this.currentAppStep = nextStep;

		if (pushState && nextStep > currentStep) {
			window.history.pushState({ step: nextStep }, '', `?step=${nextStep}`);
		} else if (!pushState) {
			window.history.replaceState({ step: nextStep }, '', `?step=${nextStep}`);
		}

		if (nextStep === 2) {
			this.attachApplyButtonListeners();
		}

		if (nextStep === 3) {
			this.updateStep4Text();
		}
	}

	showCurrentStep() {
		const urlParams = new URLSearchParams(window.location.search);
		const step = urlParams.get('step')
			? parseInt(urlParams.get('step'), 10)
			: 1;

		this.currentAppStep = step;
		this.steps[step - 1].style.display = 'block';
		this.steps[step - 1].scrollIntoView({
			behavior: 'smooth',
		});

		if (step === 2) {
			this.attachApplyButtonListeners();
		}
	}

	updateStep4Text() {
		const step4Title = document.getElementById('step4Title');
		if (step4Title) {
			step4Title.textContent =
				'Thank you for your application, it has been sent to one of our consultants who will be in touch.';
		}
	}

	handleFormSubmit(event) {
		const form = document.getElementById('applicationForm');
		if (form && form.contains(event.target)) {
			this.showStep(this.currentAppStep, 3);
		}
	}

	getJobSlugFromUrl() {
		const urlParts = window.location.pathname.split('/');
		return urlParts[urlParts.length - 2];
	}

	resetCf7Form() {
		if (this.cf7Form) {
			this.cf7Form.reset();
			wpcf7.reset(this.cf7Form);
		}
	}
}

export default JobApplication;
