class Header {
	constructor(header) {
		this.header = header;
		this.stickyHeader = document.querySelector('.sticky-header');
		this.topBar = document.querySelector('.top-bar');
		this.headerLogo = header.querySelector('.site-header__logo-image');
		this.burgerMenus = document.querySelectorAll(
			'.site-header__burger, .sticky-header__burger'
		);
		this.mobileMenu = document.querySelector('.mobile-menu');
		this.closeMobileMenuButton = this.mobileMenu.querySelector(
			'.mobile-menu__close'
		);

		this.initStickyHeader();
		this.events();
	}

	initStickyHeader() {
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 200) {
				this.stickyHeader.classList.add('sticky');
				this.topBar.classList.add('sticky');
			} else {
				this.stickyHeader.classList.remove('sticky');
				this.topBar.classList.remove('sticky');
			}
		});
	}

	events() {
		this.burgerMenus.forEach((menu) => {
			menu.addEventListener('click', () => this.toggleMobileMenu());
		});
		this.closeMobileMenuButton.addEventListener('click', () =>
			this.closeMobileMenu()
		);
	}

	toggleMobileMenu() {
		if (this.mobileMenu) {
			this.mobileMenu.classList.toggle('mobile-menu--active');
		}
	}

	closeMobileMenu() {
		this.mobileMenu.classList.remove('mobile-menu--active');
	}
}

export default Header;
