class Header {
	constructor(header) {
		this.header = header;
		this.stickyHeader = document.querySelector('.sticky-header');
		this.headerLogo = header.querySelector('.site-header__logo-image');
		this.burgerMenus = document.querySelectorAll(
			'.site-header__burger, .sticky-header__burger'
		);
		this.mobileMenu = document.querySelector('.mobile-menu');
		this.closeMobileMenuButton = this.mobileMenu.querySelector(
			'.mobile-menu__close'
		);
		this.listItems = this.mobileMenu.querySelectorAll(
			'.mobile-menu__list-item'
		);
		this.dropdownToggles = this.mobileMenu.querySelectorAll(
			'.mobile-menu__dropdown-toggle'
		);

		this.lastScrollTop = 0;

		this.initStickyHeader();
		this.events();
	}

	initStickyHeader() {
		let isScrollingUp = false;
		let scrollTimeout = null;

		window.addEventListener('scroll', () => {
			let currentScrollTop = window.pageYOffset;

			if (currentScrollTop === 0) {
				this.stickyHeader.classList.remove('sticky');
				if (scrollTimeout) clearTimeout(scrollTimeout);
				return;
			}

			if (currentScrollTop < this.lastScrollTop && currentScrollTop > 200) {
				if (!isScrollingUp) {
					isScrollingUp = true;

					if (scrollTimeout) clearTimeout(scrollTimeout);

					scrollTimeout = setTimeout(() => {
						this.stickyHeader.classList.add('sticky');
					}, 200);
				}
			} else {
				isScrollingUp = false;

				if (scrollTimeout) clearTimeout(scrollTimeout);

				this.stickyHeader.classList.remove('sticky');
			}

			this.lastScrollTop = currentScrollTop;
		});
	}

	events() {
		this.burgerMenus.forEach((menu) => {
			menu.addEventListener('click', () => this.toggleMobileMenu());
		});
		this.closeMobileMenuButton.addEventListener('click', () =>
			this.closeMobileMenu()
		);

		// Attach event listeners to the list items (except links)
		this.listItems.forEach((item) => {
			item.addEventListener('click', (e) => {
				if (!e.target.classList.contains('mobile-menu__link')) {
					this.toggleDropdown(item);
				}
			});
		});
	}

	toggleMobileMenu() {
		if (this.mobileMenu) {
			this.mobileMenu.classList.toggle('mobile-menu--active');
		}
	}

	closeMobileMenu() {
		this.mobileMenu.classList.remove('mobile-menu--active');
	}

	toggleDropdown(item) {
		const dropdown = item.querySelector('.mobile-menu__dropdown');
		const toggleButton = item.querySelector('.mobile-menu__dropdown-toggle');

		if (dropdown) {
			const maxHeight = dropdown.style.maxHeight;

			if (!maxHeight || maxHeight === '0px') {
				// Expand dropdown
				dropdown.style.maxHeight = dropdown.scrollHeight + 'px';
				if (toggleButton) {
					toggleButton.textContent = '-'; // Change icon to minus
				}
			} else {
				// Collapse dropdown
				dropdown.style.maxHeight = '0px';
				if (toggleButton) {
					toggleButton.textContent = '+'; // Change icon back to plus
				}
			}
		}
	}
}

export default Header;
