class FormHandler {
	constructor(formId, modalId, successMessage, action) {
		this.form = document.getElementById(formId);
		this.loader = document.getElementById('loader');
		this.modal = document.getElementById(modalId);
		this.successModal = document.getElementById('successModal');
		this.successModalTitle = document.getElementById('successModalLabel');
		this.successModalBody = this.successModal.querySelector('.modal-body p');
		this.successMessage = successMessage;
		this.action = action;
		this.attachEventListeners();
	}

	attachEventListeners() {
		this.form.addEventListener('submit', (event) => this.handleSubmit(event));
	}

	async handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(this.form);

		this.showLoader();
		this.closeModal(); // Close the initial modal when loading starts

		try {
			const response = await this.sendFormData(formData);
			const result = await response.json();
			this.hideLoader();

			if (result.success) {
				this.showSuccessModal(this.successMessage);
			} else {
				this.showSuccessModal(
					'Failed to submit. ' +
						(result.data.message || 'Please try again later.'),
					false
				);
			}
		} catch (error) {
			this.showSuccessModal('Failed to submit. Please try again later.', false);
			console.error('Error:', error); // Log error to console for developer debugging
		}
	}

	async sendFormData(formData) {
		return fetch(`/wp-admin/admin-ajax.php?action=${this.action}`, {
			method: 'POST',
			body: formData,
		});
	}

	showLoader() {
		this.loader.style.display = 'flex';
	}

	hideLoader() {
		this.loader.style.display = 'none';
	}

	closeModal() {
		const modalInstance = bootstrap.Modal.getInstance(this.modal);
		modalInstance.hide();
	}

	showSuccessModal(message, isSuccess = true) {
		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
		this.successModalBody.textContent = message;
		const successModal = new bootstrap.Modal(this.successModal);
		successModal.show();
	}
}

export default FormHandler;
