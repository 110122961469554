import Flickity from "flickity";

class Testimonials {
  constructor(block) {
    this.block = block;
    this.items = block.querySelector(".pelling-testimonials__testimonials");
    this.flickityInit();
  }

  flickityInit() {
    if (this.items) {
      this.flkty = new Flickity(this.items, {
        cellAlign: "center",
        wrapAround: true,
        prevNextButtons: false,
        autoPlay: 3000,
        pageDots: true,
      });
    }
  }
}

export default Testimonials;
