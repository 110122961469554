class SaveJob {
	constructor() {
		this.emailInputElement = document.getElementById('emailJobDetails');
		this.sendButtonElement = document.getElementById('sendJobDetails');
		this.jobSlug = this.getJobSlugFromUrl();
		this.siteUrl = siteData.siteUrl; // Use the localized site URL

		if (this.emailInputElement && this.sendButtonElement) {
			this.init();
		}
	}

	init() {
		this.sendButtonElement.addEventListener('click', () =>
			this.sendJobDetails()
		);
	}

	async fetchJobDetails() {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/job?slug=${this.jobSlug}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch job details');
		}
		const jobDataArray = await response.json();
		const jobData = jobDataArray[0];

		const consultantData = await this.fetchConsultantDetails(
			jobData.consultant[0]
		);

		return {
			jobData,
			consultantData,
		};
	}

	async fetchConsultantDetails(consultantId) {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/consultant/${consultantId}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch consultant details');
		}
		const consultantData = await response.json();
		return consultantData;
	}

	getJobSlugFromUrl() {
		const urlParts = window.location.pathname.split('/');
		return urlParts[urlParts.length - 2];
	}

	async sendJobDetails() {
		try {
			const email = this.emailInputElement.value;
			const { jobData, consultantData } = await this.fetchJobDetails();

			const jobTitle = jobData.acf.title;
			const jobLocation = jobData.acf.location;
			const salary = jobData.acf.salary;
			const jobLink = window.location.href;
			const jobRef = jobData.acf.job_reference_number;
			const consultantName = consultantData.name;
			const consultantEmail = consultantData.acf.email;
			const consultantPhone = consultantData.acf.phone;

			const subject = encodeURIComponent(`Job Details for ${jobTitle}`);
			const body = encodeURIComponent(
				`Here are the details of the job you are interested in:\n\n` +
					`Title: ${jobTitle}\n` +
					`Location: ${jobLocation}\n` +
					`Salary: ${salary}\n` +
					`Job Reference: ${jobRef}\n\n` +
					`Consultant: ${consultantName}\n` +
					`Consultant Email: ${consultantEmail}\n` +
					`Consultant Phone: ${consultantPhone}\n\n` +
					`View more details at: ${jobLink}\n\n`
			);

			window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
		} catch (error) {
			console.error('Error sending job details:', error);
			alert('Failed to send job details. Please try again.');
		}
	}
}

export default SaveJob;
