class ServicesWeOffer {
	constructor(block) {
		this.block = block;
		this.tabs = this.block.querySelectorAll('.pelling-services-we-offer__tab');
		this.contents = this.block.querySelectorAll(
			'.pelling-services-we-offer__content'
		);

		this.attachEvents();
	}

	attachEvents() {
		this.tabs.forEach((tab) => {
			tab.addEventListener('click', () => this.switchTab(tab));
		});
	}

	switchTab(selectedTab) {
		const targetId = selectedTab.getAttribute('data-tab');

		this.tabs.forEach((tab) => {
			tab.classList.remove('pelling-services-we-offer__tab--active');
		});
		selectedTab.classList.add('pelling-services-we-offer__tab--active');

		this.contents.forEach((content) => {
			if (content.id === targetId) {
				content.classList.add('pelling-services-we-offer__content--active');
			} else {
				content.classList.remove('pelling-services-we-offer__content--active');
			}
		});
	}
}

export default ServicesWeOffer;
