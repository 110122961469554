class FormHandler {
	constructor(formId, modalId, successMessage, action) {
		this.form = document.getElementById(formId);
		this.modal = document.getElementById(modalId);
		this.loader = document.getElementById('loader');
		this.successModal = document.getElementById('successModal');
		this.successModalTitle = document.getElementById('successModalLabel');
		this.successModalBody = this.successModal.querySelector('.modal-body p');
		this.successMessage = successMessage;
		this.action = action;

		this.attachSubmitListener();
		// this.attachModalHiddenListener();
	}

	attachSubmitListener() {
		this.form.addEventListener('submit', (event) => this.handleSubmit(event));
	}

	async handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(this.form);

		this.showLoader();
		this.closeModal();

		try {
			const response = await fetch(
				`/wp-admin/admin-ajax.php?action=${this.action}`,
				{
					method: 'POST',
					body: formData,
				}
			);
			const result = await response.json();
			this.hideLoader();

			if (result.success) {
				this.showSuccessModal(this.successMessage);
			} else {
				this.showSuccessModal(
					result.data.message || 'Please try again later.',
					false
				);
			}
		} catch (error) {
			this.showSuccessModal('Failed to submit. Please try again later.', false);
		}
	}

	attachModalHiddenListener() {
		this.modal.addEventListener('hidden.bs.modal', () => {
			this.form.reset();
			const conditionalFields = [
				'convictionDetails',
				'healthDetails',
				'adjustmentDetails',
			];
			conditionalFields.forEach((fieldId) => {
				const fieldElement = document.getElementById(fieldId);
				if (fieldElement) {
					fieldElement.style.display = 'none';
				}
			});
		});
	}

	showLoader() {
		if (this.loader) {
			this.loader.style.display = 'flex';
		}
	}

	hideLoader() {
		if (this.loader) {
			this.loader.style.display = 'none';
		}
	}

	closeModal() {
		const modalInstance = bootstrap.Modal.getInstance(this.modal);
		modalInstance.hide();
	}

	showSuccessModal(message, isSuccess = true) {
		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
		this.successModalBody.textContent = message;
		const successModalInstance = new bootstrap.Modal(this.successModal);
		successModalInstance.show();
	}
}

export default FormHandler;
