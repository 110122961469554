import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Header from './components/Header';
import ClientLogos from './components/ClientLogos';
import Testimonials from './components/Testimonials';
import ClientCandidateServices from './components/ClientCandidateServices';
import ZenonDifferenceCarousel from './components/ZenonDifferenceCarousel';
import ServicesWeOffer from './components/ServicesWeOffer';
import ProviderAccordion from './components/ProviderAccordion';
import JobApplication from './components/JobApplication';
import ApplicationForm from './components/ApplicationForm';
import SaveJob from './components/SaveJob';
import MenuUnderline from './components/MenuUnderline';
import CustomUpload from './components/CustomUpload';
import FormHandler from './components/FormHandler';
import GdprHandler from './components/GdprHandler';

for (const header of document.querySelectorAll('.site-header')) {
	new Header(header);
}

document.querySelectorAll('.pelling-client-logos').forEach((block) => {
	new ClientLogos(block);
});

document.querySelectorAll('.pelling-testimonials').forEach((block) => {
	new Testimonials(block);
});

document.querySelectorAll('.pelling-cc-services').forEach((block) => {
	new ClientCandidateServices(block);
});

document
	.querySelectorAll('.pelling-zenon-difference-carousel')
	.forEach((block) => {
		new ZenonDifferenceCarousel(block);
	});

document.querySelectorAll('.pelling-services-we-offer').forEach((block) => {
	new ServicesWeOffer(block);
});

document.querySelectorAll('.provider-accordion').forEach((accordion) => {
	new ProviderAccordion(accordion);
});

document.addEventListener('DOMContentLoaded', () => {
	document
		.querySelectorAll('.custom-upload__container')
		.forEach((formElement) => {
			new CustomUpload(formElement);
		});
});

document.addEventListener('DOMContentLoaded', () => {
	new JobApplication();
	new ApplicationForm();
	new SaveJob();
	new MenuUnderline();
	new FormHandler(
		'cvForm',
		'submitCvModal',
		'Your CV has been sent successfully!',
		'submit_cv'
	);
	new FormHandler(
		'registerForm',
		'registerJobSeekerModal',
		'You have successfully registered as a job seeker!',
		'register_job_seeker'
	);
	new FormHandler(
		'vacancyForm',
		'registerVacancyModal',
		'Your vacancy has been registered successfully!',
		'register_vacancy'
	);

	// new GdprHandler('cvForm');
	// new GdprHandler('registerForm');
	new GdprHandler('cvForm', 'submitCvModal');
	new GdprHandler('registerForm', 'registerJobSeekerModal');
});

//miscellaneous
document.addEventListener('DOMContentLoaded', () => {
	// Add classes to facetwp template
	const facetwpJobTemplate = document.querySelector(
		'.facetwp-template[data-name="job_template"]'
	);
	if (facetwpJobTemplate) {
		facetwpJobTemplate.classList.add(
			'job-listings__cards',
			'row',
			'gy-4',
			'justify-content-center'
		);
	}

	// Add classes to facetwp news template
	const facetwpNewsTemplate = document.querySelector(
		'.facetwp-template[data-name="news_template"]'
	);
	if (facetwpNewsTemplate) {
		facetwpNewsTemplate.classList.add(
			'news-listings__cards',
			'row',
			'gy-4',
			'justify-content-center'
		);
	}

	// Add event listener to search button
	const searchButton = document.getElementById('jobSearchButton');
	const resetButton = document.getElementById('resetFiltersButton');

	if (resetButton) {
		resetButton.addEventListener('click', function (e) {
			e.preventDefault();
			FWP.reset();
		});
	}

	if (searchButton) {
		searchButton.addEventListener('click', () => {
			FWP.refresh();
		});
	}

	// Add classes to service single description lists
	const descriptionContainers = document.querySelectorAll(
		'.service-single__description'
	);

	descriptionContainers.forEach((container) => {
		const uls = container.querySelectorAll('ul');

		uls.forEach((ul) => {
			const listItems = ul.querySelectorAll('li');
			if (listItems.length > 8) {
				ul.classList.add('two-columns');
			}
		});
	});

	// Add classes to facetwp load more button
	document.addEventListener('facetwp-loaded', function () {
		const loadMoreButton = document.querySelector('.facetwp-load-more');
		if (loadMoreButton) {
			loadMoreButton.classList.add(
				'btn-pelling',
				'btn-pelling--z-blue',
				'outline',
				'd-block',
				'mx-auto'
			);
		}
	});
});

// Add attibues to ACF block buttons for modals
document.querySelectorAll('#addCvModal').forEach((button) => {
	button.setAttribute('data-bs-toggle', 'modal');
	button.setAttribute('data-bs-target', '#submitCvModal');
});

document.querySelectorAll('#addJobModal').forEach((button) => {
	button.setAttribute('data-bs-toggle', 'modal');
	button.setAttribute('data-bs-target', '#registerVacancyModal');
});
