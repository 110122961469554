class JobApplication {
	constructor() {
		this.applyNowStep1 = document.querySelector(
			'.job-single__app-step--1 .provider__apply-now, .job-single__app-step--1 .job-single__apply-now'
		);
		this.applyNowStep2 = document.querySelectorAll(
			'.job-single__app-step--2 .job-single__button--apply'
		);

		this.step1 = document.querySelector('.job-single__app-step--1');
		this.step2 = document.querySelector('.job-single__app-step--2');
		this.step3 = document.querySelector('.job-single__app-step--3');
		this.step4 = document.querySelector('.job-single__app-step--4');

		this.steps = [this.step1, this.step2, this.step3, this.step4];
		this.jobSlug = this.getJobSlugFromUrl();
		this.siteUrl = siteData.siteUrl; // Use the localized site URL
		this.currentAppStep = 1;

		if (this.step1 && this.step2 && this.step3 && this.step4) {
			this.init();
		}

		document.addEventListener(
			'wpcf7mailsent',
			this.handleFormSubmit.bind(this),
			false
		);
	}

	init() {
		this.steps.forEach((step) => (step.style.display = 'none'));
		this.showCurrentStep();
		this.setHiddenFieldValues();

		this.applyNowStep1?.addEventListener('click', (e) => {
			e.preventDefault();
			this.showStep(1, 2);
		});

		this.applyNowStep2.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				this.showStep(2, 3);
			});
		});
	}

	async fetchJobDetails() {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/job?slug=${this.jobSlug}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch job details');
		}
		const jobDataArray = await response.json();
		const jobData = jobDataArray[0];

		const consultantData = await this.fetchConsultantDetails(
			jobData.consultant[0]
		);

		return {
			jobData,
			consultantData,
		};
	}

	async fetchConsultantDetails(consultantId) {
		const response = await fetch(
			`${this.siteUrl}/wp-json/wp/v2/consultant/${consultantId}`
		);
		if (!response.ok) {
			throw new Error('Failed to fetch consultant details');
		}
		const consultantData = await response.json();
		return consultantData;
	}

	async setHiddenFieldValues() {
		try {
			const { jobData, consultantData } = await this.fetchJobDetails();

			const jobTitleField = document.getElementById('app-form-job-title');
			const consultantEmailField = document.getElementById('consultant-email');

			if (jobTitleField) {
				jobTitleField.value = jobData.acf.title;
			}

			if (consultantEmailField) {
				consultantEmailField.value = consultantData.acf.email;
			}
		} catch (error) {
			console.error('Error setting hidden field values:', error);
		}
	}

	showStep(currentStep, nextStep) {
		this.steps[currentStep - 1].style.display = 'none';
		this.steps[nextStep - 1].style.display = 'block';
		this.steps[nextStep - 1].scrollIntoView({
			behavior: 'smooth',
		});
		this.currentAppStep = nextStep;

		if (nextStep === 4) {
			this.updateStep4Text();
		}
	}

	showCurrentStep() {
		this.steps[0].style.display = 'block';
		this.steps[0].scrollIntoView({
			behavior: 'smooth',
		});
	}

	updateStep4Text() {
		const step4Title = document.getElementById('step4Title');
		if (step4Title) {
			step4Title.textContent =
				'Thank you for your application, it has been sent to one of our consultants who will be in touch.';
		}
	}

	handleFormSubmit(event) {
		const form = document.getElementById('applicationForm');
		if (form && form.contains(event.target)) {
			this.showStep(this.currentAppStep, 4);
		}
	}

	getJobSlugFromUrl() {
		const urlParts = window.location.pathname.split('/');
		return urlParts[urlParts.length - 2];
	}
}

export default JobApplication;
