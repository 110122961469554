class MenuUnderline {
	constructor() {
		this.menu = document.querySelector('.main-menu__list');
		this.menuItems = document.querySelectorAll('.main-menu__list-item');
		this.underline = document.createElement('div');
		this.underline.classList.add('main-menu__underline');
		this.menu.appendChild(this.underline);
		this.addEventListeners();
		this.moveUnderlineToCurrent(); // Initially position underline
	}

	setUnderline(position, width) {
		this.underline.style.left = `${position}px`;
		this.underline.style.width = `${width}px`;
	}

	moveUnderlineToCurrent() {
		const currentItem = this.menu.querySelector(
			'.main-menu__list-item.current'
		);
		if (currentItem) {
			this.updateUnderlinePosition(currentItem);
		} else {
			// If no current item, shrink the underline to 0 width
			this.setUnderline(
				this.underline.offsetLeft + this.underline.offsetWidth / 2,
				0
			);
		}
	}

	updateUnderlinePosition(element) {
		const textWidth = element.querySelector('a')
			? element.querySelector('a').offsetWidth
			: element.clientWidth;
		const centerPosition =
			element.offsetLeft + element.offsetWidth / 2 - textWidth / 2;
		this.setUnderline(centerPosition, textWidth);
	}

	addEventListeners() {
		this.menuItems.forEach((item) => {
			item.addEventListener('mouseenter', () => {
				this.updateUnderlinePosition(item);
			});

			item.addEventListener('mouseleave', () => {
				this.moveUnderlineToCurrent();
			});

			// Handling click events to move the underline permanently to the clicked item
			item.addEventListener('click', () => {
				this.updateUnderlinePosition(item);
				this.manageCurrentItem(item);
			});
		});
	}

	manageCurrentItem(newCurrentItem) {
		// Remove the current class from all items
		this.menuItems.forEach((item) => {
			item.classList.remove('current');
		});

		// Add the current class to the newly clicked item
		newCurrentItem.classList.add('current');
	}
}

export default MenuUnderline;
