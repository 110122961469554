class CustomUpload {
	constructor() {
		this.formElement = document.querySelector('.custom-upload__container');
		this.labelSelector = '.custom-upload__label';
		this.maxLength = 35;

		if (this.formElement) {
			this.init();
		}
	}

	init() {
		this.removeBreaks(this.formElement);

		this.inputElement = this.formElement.querySelector('input[type="file"]');
		this.labelElement = this.formElement.querySelector(this.labelSelector);

		this.inputElement.addEventListener('change', () => this.handleFileChange());
	}

	removeBreaks(element) {
		const brTags = element.querySelectorAll('br');
		brTags.forEach((br) => br.remove());
	}

	handleFileChange() {
		if (this.inputElement.files.length > 0) {
			const formattedName = this.formatFileName(
				this.inputElement.files[0].name
			);
			this.labelElement.textContent = `${formattedName}`;
		} else {
			this.labelElement.textContent = 'Upload a CV';
		}
	}

	formatFileName(fileName) {
		if (fileName.length <= this.maxLength) {
			return fileName; // Return the original name if it's short enough.
		}

		const half = Math.floor((this.maxLength - 3) / 2);
		const start = fileName.slice(0, half); // Get the starting part.
		const end = fileName.slice(-half); // Get the ending part with the extension.

		return `${start}...${end}`; // Combine with ellipses in the middle.
	}
}

export default CustomUpload;
