import Flickity from 'flickity';
import 'flickity-fade';

class ZenonDifferenceCarousel {
	constructor(block) {
		this.block = block;
		this.imagesElement = block.querySelector(
			'.pelling-zenon-difference-carousel__images'
		);
		this.textElement = block.querySelector(
			'.pelling-zenon-difference-carousel__text-slides'
		);
		this.initFlickity();
		this.setupHoverEvents();
		this.handleResize();
	}

	initFlickity() {
		this.imagesFlickity = new Flickity(this.imagesElement, {
			cellAlign: 'center',
			prevNextButtons: true,
			pageDots: false,
			draggable: true,
			autoPlay: 5000,
			resize: true,
			fade: true,
		});

		this.textFlickity = new Flickity(this.textElement, {
			cellAlign: 'center',
			contain: false,
			wrapAround: false,
			prevNextButtons: false,
			pageDots: true,
			draggable: true,
			resize: true,
			fade: true,
			adaptiveHeight: window.innerWidth < 1400,
		});

		this.imagesFlickity.on('change', (index) => {
			if (this.textFlickity.selectedIndex !== index) {
				this.textFlickity.select(index);
			}
		});

		this.textFlickity.on('change', (index) => {
			if (this.imagesFlickity.selectedIndex !== index) {
				this.imagesFlickity.select(index);
			}
		});
	}

	setupHoverEvents() {
		this.block.addEventListener('mouseenter', () => {
			this.imagesFlickity.pausePlayer();
		});

		this.block.addEventListener('mouseleave', () => {
			this.imagesFlickity.unpausePlayer();
		});
	}

	handleResize() {
		window.addEventListener('resize', () => {
			const isUnder1400 = window.innerWidth < 1400;
			this.textFlickity.options.adaptiveHeight = isUnder1400;
			this.textFlickity.resize();
		});
	}
}

export default ZenonDifferenceCarousel;
