class SubmitCv {
	constructor() {
		this.form = document.getElementById('cvForm');
		this.loader = document.getElementById('loader');
		this.submitCvModal = document.getElementById('submitCvModal');
		this.successModal = document.getElementById('successModal');
		this.successModalTitle = document.getElementById('successModalLabel');
		this.successModalBody = this.successModal.querySelector('.modal-body p');
		this.attachEventListeners();
	}

	attachEventListeners() {
		this.form.addEventListener('submit', (event) => this.handleSubmit(event));
	}

	async handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(this.form);

		this.showLoader();
		this.closeSubmitCvModal(); // Close the initial modal when loading starts

		try {
			const response = await this.sendFormData(formData);
			const result = await response.json();
			this.hideLoader();

			if (result.success) {
				this.showSuccessModal('Your CV has been sent successfully!');
			} else {
				this.showSuccessModal(
					'Failed to send CV. ' +
						(result.data.message || 'Please try again later.'),
					false
				);
			}
		} catch (error) {
			this.showSuccessModal(
				'Failed to send CV. Please try again later.',
				false
			);
			console.error('Error:', error); // Log error to console for developer debugging
		}
	}

	async sendFormData(formData) {
		return fetch('/wp-admin/admin-ajax.php?action=submit_cv', {
			method: 'POST',
			body: formData,
		});
	}

	showLoader() {
		this.loader.style.display = 'flex';
	}

	hideLoader() {
		this.loader.style.display = 'none';
	}

	closeSubmitCvModal() {
		const submitCvModal = bootstrap.Modal.getInstance(this.submitCvModal);
		submitCvModal.hide();
	}

	showSuccessModal(message, isSuccess = true) {
		this.successModalTitle.textContent = isSuccess ? 'Success' : 'Error';
		this.successModalBody.textContent = message;
		const successModal = new bootstrap.Modal(this.successModal);
		successModal.show();
	}
}

export default SubmitCv;
